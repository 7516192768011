import { handleLinkClick } from "./global";

const state = {
    pageElements: {}
}

export default function init() {
    searchElements()
    registerHandlers()
}

function searchElements() {
    state.pageElements.portfolioOverlays = document.querySelectorAll('.dj-gallery__overlay');
    state.pageElements.heroCTA = document.querySelector('.js-hero-cta');
}

function registerHandlers() {
    state.pageElements.heroCTA.addEventListener('click', handleLinkClick);
}