import '../assets/images/logo.svg';

import '../assets/images/decoration-group-1.svg';
import '../assets/images/decoration-group-2.svg';
import '../assets/images/decoration-group-3-mobile.svg';
import '../assets/images/decoration-group-3-desktop.svg';
import '../assets/images/decoration-group-4-desktop.svg';
import '../assets/images/decoration-group-4-mobile.svg';
import '../assets/images/decoration-group-5.svg';
import '../assets/images/decoration-group-6.svg';
import '../assets/images/decoration-group-7.svg';
import '../assets/images/decoration-group-8.svg';

import '../assets/images/designer.png';
import '../assets/images/cookie.png';

import '../assets/images/project-1.png';
import '../assets/images/project-2.png';
import '../assets/images/project-3.jpg';
import '../assets/images/project-4.jpg';
import '../assets/images/project-5.jpg';
import '../assets/images/project-6.png';

import "../assets/images/individual-1.png";
import "../assets/images/individual-2.png";
import "../assets/images/vaclav-1.jpg";
import "../assets/images/vaclav-2.jpg";
import "../assets/images/fantom-1.png";
import "../assets/images/fantom-2.png";
import "../assets/images/fantom-3.png";
import "../assets/images/fantom-4.png";
import "../assets/images/fungl-1.jpg";
import "../assets/images/fungl-2.jpg";
import "../assets/images/fungl-3.jpg";
import "../assets/images/fungl-4.jpg";
import "../assets/images/gala-design-1.png";
import "../assets/images/gala-design-2.png";
import "../assets/images/deer-codes-1.png";
import "../assets/images/deer-codes-2.png";