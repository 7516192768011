import './sass/main.scss';
import './js/images';
import './js/ga';
import global from './js/global';
import homepage from './js/homepage';
import projectDetail from './js/project-detail';

window.onload = () => {
    global();

    if (!window.location.search) {
        homepage();
        return;
    }

    projectDetail();
};
