import data from "../data/projects.json";

const state = {
    pageElements: {},
    projectTitle: null,
}

export default function init() {
    state.projectTitle = window.location.search.split('?project-name=')[1];

    searchElements();
    render();
}

function searchElements() {
    state.pageElements.projectGallery = document.querySelector('.js-project-gallery');
    state.pageElements.projectTitle = document.querySelector('.js-project-name');
    state.pageElements.projectType = document.querySelector('.js-project-type');
    state.pageElements.projectDesc = document.querySelector('.js-project-desc');
    state.pageElements.projectLink = document.querySelector('.js-project-link');
}

function render() {
    renderText();
    renderImages();
}

function renderText() {
    const documentFragment = document.createDocumentFragment();
    const { projectTitle, projectType, projectDesc } = state.pageElements;

    projectTitle.innerHTML = data[state.projectTitle].title;
    projectType.innerHTML = data[state.projectTitle].type;

    data[state.projectTitle].desc.forEach(descParagraph => {
        const paragraph = document.createElement('p');

        paragraph.innerHTML = descParagraph;
        documentFragment.appendChild(paragraph)
    });

    if (data[state.projectTitle].link) {
        state.pageElements.projectLink.href = data[state.projectTitle].link
    } else {
        state.pageElements.projectLink.style.display = "none"
    }

    projectDesc.insertBefore(documentFragment, state.pageElements.projectLink);
}

function renderImages() {
    const documentFragment = document.createDocumentFragment();

    data[state.projectTitle].images_src.forEach(src => {
        const imageContainer = document.createElement('div');
        const image = document.createElement('img');

        imageContainer.classList.add('dj-gallery__image-cont');
        image.classList.add('dj-gallery__image-el');

        image.src = src;

        imageContainer.appendChild(image);
        documentFragment.appendChild(imageContainer);
    });

    state.pageElements.projectGallery.appendChild(documentFragment);
}