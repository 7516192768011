const state = {
    pageElements: {},
    activeLink: null,
    viewportWidth: 0,
    decorationsDisabled: true
}

export default function init() {
    searchElements()
    registerHandlers()

    state.pageElements.cpYear.innerHTML = new Date().getFullYear().toString();
}

function searchElements() {
    state.pageElements.header = document.querySelector('.dj-l-header');
    state.pageElements.arrowUp = document.querySelector('.dj-contact__arrow-cont');
    state.pageElements.burgerButton = document.querySelector('.js-burger-button');
    state.pageElements.crossButton = document.querySelector('.js-cross-button');
    state.pageElements.mobileNav = document.querySelector('.dj-l-navigation');
    state.pageElements.navLinks = document.querySelector('.dj-navigation__list');
    state.pageElements.desktopLinks = document.querySelectorAll('.dj-navigation--desktop .dj-navigation__link');
    state.pageElements.logo = document.querySelector('.js-logo');
    state.pageElements.desktopLogo = document.querySelector('.js-logo-desktop');
    state.pageElements.sections = document.querySelectorAll('.dj-l-section');
    state.pageElements.cpYear = document.querySelector('.js-cp-year');
}

function registerHandlers() {
    window.addEventListener('scroll', handleHeaderShadow);
    window.addEventListener('scroll', handleArrowUpVisibility);
    window.addEventListener('scroll', updateNavDecorations);

    if (window.location.pathname === '/') {
        handleWindowResize();
        window.addEventListener('resize', handleWindowResize);
    }

    state.pageElements.arrowUp.addEventListener('click', handleScrollTop);
    state.pageElements.burgerButton.addEventListener('click', handleNavOpen);
    state.pageElements.crossButton.addEventListener('click', handleNavClose);
    state.pageElements.navLinks.addEventListener('click', handleNavClose);
    state.pageElements.logo.addEventListener('click', handleNavClose);
    state.pageElements.desktopLogo.addEventListener('click', handleScrollTop);
    state.pageElements.desktopLinks.forEach(link => {
        link.addEventListener('mouseenter', handleLinkEnter);
        link.addEventListener('mouseleave', handleLinkLeave);
        link.addEventListener('click', handleLinkClick);
    });
}

function handleHeaderShadow() {
    if (!state.pageElements.hasOwnProperty("header")) {
        console.error(".dj-l-header element was NOT found")
        return;
    }

    if (window.scrollY > 25){
        state.pageElements.header.classList.add("dj-l-header--shadow");
    } else {
        state.pageElements.header.classList.remove("dj-l-header--shadow");
    }
}

function handleArrowUpVisibility() {
    if (!state.pageElements.hasOwnProperty("arrowUp")) {
        console.error(".dj-contact__arrow-cont element was NOT found")
        return;
    }

    const documentHeight = document.documentElement.scrollHeight;
    const viewportHeight = window.innerHeight;

    if (documentHeight - viewportHeight - window.scrollY < 80){
        state.pageElements.arrowUp.classList.add("dj-contact__arrow-cont--visible");
    } else {
        state.pageElements.arrowUp.classList.remove("dj-contact__arrow-cont--visible");
    }
}


function handleScrollTop() {
    state.decorationsDisabled = true;
    window.scrollTo({ top: 0, behavior: 'smooth' });
    removeHash();

    setTimeout(() => {
        state.decorationsDisabled = false;

        if (!state.activeLink) {
            return;
        }

        decorationDeactivate(getLinkDecoration(state.activeLink))
        state.activeLink = null;
    }, 700)
}

function handleNavOpen() {
    if (!state.pageElements.hasOwnProperty("mobileNav")) {
        console.error(".dj-l-navigation element was NOT found")
        return;
    }

    state.pageElements.mobileNav.classList.add("dj-l-navigation--opened")
}

function handleNavClose(e) {
    if (!state.pageElements.hasOwnProperty("mobileNav")) {
        console.error(".dj-l-navigation element was NOT found")
        return;
    }

    if (e.currentTarget !== state.pageElements.logo
        && e.target !== state.pageElements.crossButton
        && !e.target.classList.contains('dj-navigation__link'))
    {
        return;
    }

    state.pageElements.mobileNav.classList.remove("dj-l-navigation--opened")
}

function handleLinkEnter(e) {
    const decoration = getLinkDecoration(e.currentTarget)

    if (!decoration) {
        console.error("link decoration element was NOT found")
        return;
    }

    decorationActivate(decoration);
}

function handleLinkLeave(e) {
    const decoration = getLinkDecoration(e.currentTarget)

    if (!decoration) {
        console.error("link decoration element was NOT found")
        return;
    }

    if (state.activeLink === e.currentTarget) {
        return;
    }

    decorationDeactivate(decoration);
}

export function handleLinkClick(e) {
    state.decorationsDisabled = true;
    const link = e.target
    decorationActivate(getLinkDecoration(link));

    if (state.activeLink) {
        decorationDeactivate(getLinkDecoration(state.activeLink));
    }

    state.activeLink = link;

    setTimeout(() => {
        state.decorationsDisabled = false;
    }, 700)
}

function handleWindowResize() {
    state.viewportWidth = getViewportWidth();

    if (state.viewportWidth <= 1263) {
        return;
    }

    state.decorationsDisabled = false;
}

function updateNavDecorations() {
    if (state.decorationsDisabled) {
        return;
    }

    const [ ...sections ] = state.pageElements.sections

    for (let i = 0; i < sections.length; i++) {
        const id = getSectionAnchor(sections[i]).id;
        const rect = sections[i].getBoundingClientRect().y;
        const pageData = {id: id, rect: rect};

        const documentHeight = document.documentElement.scrollHeight;
        const viewportHeight = window.innerHeight;
        if (pageData.rect > -500 && pageData.rect < 100) {
            const anchor = getSectionAnchor(sections[i])
            setActiveLink(anchor);
        }

        const distanceFromBottom = documentHeight - viewportHeight - window.scrollY;
        if (distanceFromBottom < 200){
            const anchor = getSectionAnchor(sections[sections.length - 1])
            setActiveLink(anchor);
            return;
        }
    }
}

function getViewportWidth() {
    return Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
}

function getSectionAnchor(section) {
    return section.querySelector('.dj-l-section__anchor');
}

function getAnchorLink(sectionId) {
    const queryString = `.dj-navigation--desktop .dj-navigation__link[href="#${sectionId}"]`
    return document.querySelector(queryString);
}

function decorationActivate(linkDecoration) {
    linkDecoration.classList.add('dj-navigation__link-decoration--active')
}

function decorationDeactivate(linkDecoration) {
    linkDecoration.classList.remove('dj-navigation__link-decoration--active')
}

function getLinkDecoration(linkEl) {
    return linkEl.nextElementSibling;
}

function setActiveLink(anchor) {
    if (!anchor) {
        return
    }

    const currentSectionLink = getAnchorLink(anchor.id);

    if (typeof currentSectionLink === 'undefined' || currentSectionLink === state.activeLink) {
        return;
    }

    const [ ...desktopLinks ] = state.pageElements.desktopLinks
    desktopLinks.forEach(desktopLink => {
        decorationDeactivate(getLinkDecoration(desktopLink));
    })

    if (!currentSectionLink) {
        state.activeLink = null;
        return;
    }

    decorationActivate(getLinkDecoration(currentSectionLink));
    state.activeLink = currentSectionLink;
}

function removeHash() {
    history.pushState("", document.title, window.location.pathname + window.location.search);
}